import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import Slider from "../carousel/slider.js";
import CardList from '../card/cardList';

const TeamList = ({useSlider=true}) => {
    const data = useStaticQuery(graphql`
    query {
        allContentfulPerson (sort: {order: ASC, fields: order}){
            edges {
                node {
                id,
                name,
                slug,
                title,
                email,
                linkedIn,
                imageDetail {
                  fluid(maxWidth: 1180,quality:90, background: "rgb:000000") {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                shortBio {
                  childMarkdownRemark {
                    html,
                    excerpt
                  }
                },
                image {
                    fluid(maxWidth: 350,quality: 90) {
                      ...GatsbyContentfulFluid_withWebp
                    }
                  }
                }
            }
        }
    }
  `)

  const team = data.allContentfulPerson.edges.map(it=>it.node);
    return ( <>
    <h2 className={useSlider ? 'no-border':''}>Derichs &amp;<br /> Graalmann &amp;<br /> Team</h2>
      {
        useSlider ?
      <>
      <Slider items={team} />
      <Link className="show-all" to="/team-und-partner">Das ganze Team ansehen</Link>
      </>
      :
      <CardList style={{marginTop:40}} isTeam={true} items={team}></CardList>
}
      {/*<Carousel items={team} />*/}
        </> );
}
 
export default TeamList;