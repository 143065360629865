import React from "react";
import Slider from "../carousel/slider.js";
import { graphql, Link, useStaticQuery } from "gatsby";
import dayjs from "dayjs";

/*const Slides = [
    {title:'Corporate Publishing',template:'Twitter',date:'12.12.2019'},
    {title:'Digitaler Content',template:'Projekte',image:'./assets/images/mockup.png'},
    {title:'Kommunikationsstrategien & Medienarbeit',template:'Projekte',image:'./assets/images/tsg-webseite-mockup_air.png'},
    {title:'Sonstiges',template:'FB 500'},
  ];
  
  
  query {
    allTwitterStatusesUserTimelineTimeline(limit: 1) {
        edges {
          node {
            created_at
            full_text
            source
            id_str
            entities {
              media {
                media_url_https
              }
              urls {
                url
              }
            }
          }
        }
      }

  */

const News = ({ showLink }) => {
  const data = useStaticQuery(graphql`
    query {
      allContentfulFb500 {
        edges {
          node {
            slug
            title
            date: publishDate(formatString: "YYYY.MM.DD")
            updatedAt(formatString: "YYYY.MM.DD")
            fb500links {
              datum(formatString: "YYYY.MM.DD")
              title
              url
            }
            image: heroImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              childMarkdownRemark {
                html
                excerpt
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
          }
        }
      }
      allContentfulArtikel(sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            title
            slug
            publishDate(formatString: "MMMM Do, YYYY")
            date: publishDate
            tags
            leistung
            template: produkt
            highlight
            kunde {
              name
              id
            }
            image: heroImage {
              fluid(maxWidth: 600) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            description {
              childMarkdownRemark {
                html
                excerpt
              }
            }
            body {
              childMarkdownRemark {
                html
              }
            }
            kunde {
              name
              url
              body {
                childMarkdownRemark {
                  html
                }
              }
              logo {
                fluid(maxWidth: 350, maxHeight: 350, resizingBehavior: SCALE) {
                  ...GatsbyContentfulFluid_withWebp
                }
                file {
                  fileName
                  url
                  details {
                    image {
                      height
                      width
                    }
                  }
                }
              }
              artikel {
                title
                slug
                publishDate(formatString: "MMMM Do, YYYY")
                tags
                leistung
                template: produkt
                kunde {
                  name
                  id
                }
                image: heroImage {
                  fluid(maxWidth: 600) {
                    ...GatsbyContentfulFluid_withWebp
                  }
                }
                description {
                  childMarkdownRemark {
                    html
                    excerpt
                  }
                }
              }
            }
          }
        }
      }
    }
  `);

  let articles = data.allContentfulArtikel.edges.map((it) => it.node);
  /*const tweet = data.allTwitterStatusesUserTimelineTimeline.edges.map(
    (it) => it.node
  );*/
  let fb500 = data.allContentfulFb500.edges.map((it) => it.node);

  articles.forEach((a) => {
    a.date = dayjs(a.date);
  });

  if (fb500) {
    fb500.template = "Projekt";
    fb500.date = dayjs(fb500.date);
  }
  /*if (tweet) {
    var t = tweet[0];
    t.category = "Twitter";
    t.date = dayjs(t.created_at);
    t.description = {
      childMarkdownRemark: { excerpt: `${t.full_text}` },
    };
    if (t.entities) t.image = t.entities;
  }*/
  //const sortedArticles = [...tweet.concat(articles)].sort((a,b)=>dayjs(a.date).isBefore(dayjs(b.date)));
  const sortedArticles = [...articles].sort((a, b) =>
    dayjs(a.date).isBefore(dayjs(b.date))
  );
  return (
    <>
      <h2 className="no-border">Aktuelles</h2>
      <Slider items={sortedArticles} />
      {showLink && (
        <Link className="show-all" to="/kunden-leistungen">
          Alle Projekte ansehen
        </Link>
      )}
    </>
  );
};

export default News;
