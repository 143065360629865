import React, { useEffect, useState } from 'react';
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import Card from '../card/card';
import { motion, AnimatePresence, AnimateSharedLayout } from 'framer-motion';
import styled from '@emotion/styled';
import { stagger, fadeIn } from '../../config/motions';
import PeopleCard from '../card/peopleCard';
import CardDetail from '../card/cardDetail';
import Button from '../elements/button';
import { useWindowSize } from '../../utils/hooks';
import SliderArrows from './sliderControls';


const StyledSliderHolder = styled.div`
    position: relative;
    &:before {
      content:'';
      left:-60px;
      height: 100%;
      position:absolute;
      top: 0;
      width: calc(100% + 120px);
    }
`

const StyledSlider = styled(motion.div)`
//overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: -10px;
    margin-right: -10px;
`

const Slider = ({ items }) => {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [selectedId, setSelectedId] = useState(null)
  const [visibleSlides, setVisibleSlides] = React.useState([]);
  const [slidesPerView, setSlidesPerView] = React.useState(3);
  const [width] = useWindowSize();
  useEffect(() => {
    if (width > 0) {
      setSlidesPerView(width > 639 ? width > 1024 ? 3 : 2 : 1);
    }
  }, [width]);

  const [sliderRef, slider] = useKeenSlider({
    //initial: 0,centered:false,
    //mode: "free-snap",
    //spacing: 15,
    slidesPerView: slidesPerView,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide);
    },
    breakpoints: {
      '(max-width: 639px)': {
        slidesPerView: 1
      },
      '(min-width: 640px) and (max-width: 1000px)': {
        slidesPerView: 2
      },
    },
  });

  const hasDetail = () => { return (items && items.find(it => it.template) !== undefined) }


  useEffect(() => {
    setVisibleSlides([...Array(slidesPerView).keys()].map(i => i + currentSlide))
  }, [currentSlide, slidesPerView])

  const onOpen = (e, index) => {
    e.preventDefault();
    e.stopPropagation();
    setSelectedId(index);
    console.log('open card')
  }

  return (<StyledSliderHolder className="slider__holder">
    {slider && (
      <div className="dots">
        {[...Array(slider.details().size).keys()].map(idx => {
          return (
            <button
              name="button-dot"
              aria-label="Slider Dot-Button"
              key={idx}
              onClick={() => {
                slider.moveToSlideRelative(idx);
              }}
              className={"dot" + (visibleSlides.indexOf(idx) >= 0 ? " active" : "")}
            />
          );
        })}
      </div>
    )}
    {/* <Button name="destroy" onClick={()=>slider.destroy()}>Destroy</Button> */}

    <StyledSlider
      initial='initial'
      animate='animate'
      variants={stagger} ref={sliderRef} className="keen-slider">
      {
        items && items.slice(0, 6).map((item, index) =>
          <motion.div
            key={`slider__${index}`}
            variants={fadeIn}
            className="keen-slider__slide">
            {hasDetail() ? <Card onOpen={onOpen} id={index} data={item} />
              : <PeopleCard index={index} data={item} />}
          </motion.div>
        )
      }
    </StyledSlider>
    {slider && <SliderArrows slider={slider} slidesPerView={slidesPerView} currentSlide={currentSlide} />}


    {/*
           hasDetail() ?
         
           <SliderArrows slider={slider} currentSlide={currentSlide} />

    <StyledSlider initial='initial' animate='animate' variants={stagger} ref={sliderRef} className="keen-slider">
        {
        items && items.map((item,index) =>  
            <motion.div
           
            //onClick={(e)=>onClick(e,index)}
            key={`slider__${index}`}
            //layoutId={`card-container-${index}`}
            variants={fadeIn}
            //id={index}
            className="keen-slider__slide">
              <Card onOpen={onOpen} id={index} data={item} />
        
            </motion.div>
        )
    }
    
       </StyledSlider>
     
 :
 <StyledSlider initial='initial' animate='animate' variants={stagger} ref={sliderRef} className="keen-slider">
 {
 items && items.map((item,index) =>  
     <div
     //onClick={(e)=>onClick(e,index)}
     key={`slider-simple__${index}`}
     variants={fadeIn}
     id={index}
     whileHover={{ scale: 1.06 }}
     className="keen-slider__slide">
       <PeopleCard index={index} data={item} />
 
     </div>
 )
}

</StyledSlider}>*/}


  </StyledSliderHolder>);
}



export default Slider;