import React,{useState} from 'react'
import Img from 'gatsby-image'
import styles from './hero.module.css'

import spieler1 from '../icons/spieler-1.svg'
import spieler2 from '../icons/spieler-2.svg'
import spieler3 from '../icons/spieler-3.svg'

import {
  useViewportScroll,
  motion,
  useTransform
} from 'framer-motion';
//import { useInView } from 'react-intersection-observer';
import Bubble from '../elements/bubble';


/*
const { scrollY } = useViewportScroll();
  const y1 = useTransform(scrollY, [0, 300], [0, 200]);
  const y2 = useTransform(scrollY, [0, 300], [0, -100]);

  const [ref, inView, entry] = useInView({
    threshold: 0.5,
    triggerOnce: false
  });
*/

 const Hero = ({ data }) => {
  const { scrollY } = useViewportScroll();
  const { scrollYProgress } = useViewportScroll()
  const y1 = useTransform(scrollY, [0, 400], [0, -100]);
  //const y2 = useTransform(scrollY, [0, 600], [0, -50]);
  const y3 = useTransform(scrollY, [0, 100], [0, -1]);
  const [playerNum, setPlayerNum] = useState(Math.floor(Math.random() * 3)+1);
  //const playerNum=1;

  return (<div className={styles.hero}>
   
    <motion.img style={{ y: y1, x: -100,opacity:playerNum===1?1:0 }} alt="Illustration: Fußballer" src={spieler1} className={styles.heroImg}/>
    <motion.img style={{ y: y1, x: -100,opacity:playerNum===2?1:0 }} alt="Illustration: Fußballer" src={spieler2} className={styles.heroImg2}/>
    <motion.img style={{ y: y1, x: -100,opacity:playerNum===3?1:0 }} alt="Illustration: Fußballer" src={spieler3} className={styles.heroImg3}/>
    {/*
      playerNum===1 &&
    <motion.div className={styles.ball} style={{ y: y2, x: 60 }}><Bubble style={{marginTop: 70,width:90}} /></motion.div>
    */}
    <motion.div style={{ y: y3, x: 0 }} className={styles.heroDetails}>
      <h1 className={styles.heroHeadline}><span>{`Sport &
       Kommunikation`/*data.name*/}</span></h1>
      {`Für uns 
      ein Heimspiel`/*data.title*/}
    </motion.div>
  </div>)
 }

 export default Hero;